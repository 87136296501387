import React, { useEffect, useState } from 'react';
import { Link, useLocation, Navigate } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SuccessPage() {
  const [isValid, setIsValid] = useState(null);
  const query = useQuery();

  useEffect(() => {
    const verifyToken = async () => {
      const session_id = query.get('session_id');
      const token = query.get('token');
    
      console.log(`Verifying token: ${process.env.REACT_APP_LAMBDA_URL_VERIFY}?session_id=${session_id}&token=${token}`);
    
      try {
        const response = await fetch(`${process.env.REACT_APP_LAMBDA_URL_VERIFY}?session_id=${session_id}&token=${token}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        const data = await response.json();
        console.log('Verification response:', data);
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}, message: ${data.message}`);
        }
        
        setIsValid(data.valid);
        if (data.message === 'Token already used') {
          // Handle the case where the token is valid but already used
          console.log('Token is valid but already used');
          // You might want to show a different message to the user in this case
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setIsValid(false);
      }
    };
  
    if (query.get('session_id') && query.get('token')) {
      verifyToken();
    }
  }, [query]);

  if (isValid === null) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  if (isValid === false) {
    return <Navigate to="/" replace />;
  }
  
  return (
    <div className="max-w-2xl mx-auto mt-16 px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          Payment 
          <span className="inline-block bg-blue-500 text-white px-4">Successful!</span>
        </h1>
        <div className="mt-8">
          <svg className="w-16 h-16 mx-auto mb-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <p className="text-xl mb-4 text-gray-700">
            Thank you for your support! Your payment has been processed successfully.
          </p>
          <p className="text-lg mb-6 text-gray-600">
            We've sent your unique redemption code to your email. Please check your inbox (and spam folder, just in case) for further instructions on how to get started with your AI Fitness Coach.
          </p>
          <ul className="space-y-4 text-left text-gray-600 max-w-md mx-auto mb-8">
            <li className="flex items-center">
              <svg className="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="ml-3 text-lg"><strong>Access granted</strong> to AI-powered workout plans</span>
            </li>
            <li className="flex items-center">
              <svg className="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="ml-3 text-lg"><strong>Personalized nutrition advice</strong> now available</span>
            </li>
            <li className="flex items-center">
              <svg className="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="ml-3 text-lg"><strong>24/7 support</strong> from your AI fitness coach</span>
            </li>
          </ul>
          <Link to="/" className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-md text-lg transition duration-300 shadow-md">
            Return to Home
          </Link>
        </div>
        <p className="mt-6 text-gray-600">
          If you have any questions or need assistance, please don't hesitate to contact our support team.
        </p>
      </div>
    </div>
  );
}

export default SuccessPage;