import { Link } from 'react-router-dom';

import { useState, useEffect } from 'react';
import iconImage from '../images/icon.png';




const HeaderMobile = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleJoinNow = () => {
        window.open('https://t.me/aifitnesstrainerbot', '_blank');
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isMenuOpen]);

    return (
        <nav className="relative z-50 py-6 mb-10 sm:hidden">
            <section className='fixed w-full'>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
                    <div className="flex items-center space-x-3 ml-2 sm:-ml-8">
                        <Link to="/">
                            <img
                                src={iconImage}
                                alt="trAIner icon"
                                className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 rounded-full object-cover"
                            />
                        </Link>
                    </div>
                    <div className="flex items-center space-x-4">
                        <button onClick={handleJoinNow} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 md:py-3 md:px-6 ml-4 rounded-full transition duration-300 shadow-md text-base md:text-lg lg:text-xl xl:text-xl">
                            Join Now
                        </button>
                        {/* Mobile Menu Button */}
                        <div className="relative">
                            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="text-gray-600 hover:text-gray-900 p-2">
                                <svg className="w-6 h-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                                </svg>
                            </button>
                            {/* Mobile Menu Dropdown */}
                            {/* Overlay */}
                            {isMenuOpen && (
                                <div className="fixed inset-0 bg-black bg-opacity-50 z-20" onClick={() => setIsMenuOpen(false)}></div>
                            )}

                            {/* Side Menu */}
                            {isMenuOpen && (
                                <div className="fixed top-0 right-0 w-64 h-full bg-white shadow-md z-30">
                                    <div className="p-4 flex flex-col gap-2 pt-14">
                                        <div className='flex flex-row gap-2 items-center mb-6'>
                                            <img
                                                src={iconImage}
                                                alt="trAIner icon"
                                                className="w-14 h-14 rounded-full object-cover"
                                            />
                                            <Link to="/" className="text-xl font-bold text-gray-800">trAIner</Link>
                                        </div>
                                        <Link to="/#features" onClick={() => { setIsMenuOpen(false) }} className="block px-4 py-2 text-lg text-gray-600 hover:bg-gray-100">Features</Link>
                                        <Link to="/blog" onClick={() => { setIsMenuOpen(false) }} className="block px-4 py-2 text-lg text-gray-600 hover:bg-gray-100">Blog</Link>
                                        <a href="mailto:trainerfitnesscoach@gmail.com" onClick={() => { setIsMenuOpen(false) }} className="block px-4 py-2 text-lg text-gray-600 hover:bg-gray-100">Contact</a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </nav>
    );
}
export default HeaderMobile;