import React from 'react';
import { useMediaQuery } from 'react-responsive';

const TrainerImageComponent = ({ trainerImage, handleImageError }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const mobileStyles = {
    width: '0%', // Increased from 100% to make the image bigger
    height: 'auto',
    maxHeight: '0px', // Increased from 300px
    marginLeft: '20%', // This will push the image to the right
  };

  const desktopStyles = {
    position: 'absolute',
    width: '200%',
    height: '600px',
    right: '-106%',
    top: '-10%',
  };

  return (
    <div className={`relative ${isDesktop ? 'w-full' : 'w-full'}`} style={isDesktop ? desktopStyles : {}}>
      <img 
        src={trainerImage} 
        alt="trAIner App Demo" 
        className="w-full h-full object-contain"
        style={{
          ...(isDesktop ? {} : mobileStyles),
          filter: 'drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08))'
        }}
        onError={handleImageError}
      />
    </div>
  );
};

export default TrainerImageComponent;