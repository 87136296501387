import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import hiitWorkoutImage from './images/hiit.jpg';
import macroTrackingImage from './images/macro_tracking.jpg';
import strengthTrainingImage from './images/bench_press.jpg';
import nutritionTipsImage from './images/nutrition_tips.jpg';
import aiToolsImage from './images/ai_tools.jpg';
import macroTrackingImage2 from './images/macro_tracking_2.jpg';
import adaptiveWorkoutsImage from './images/train copy.jpg';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const blogPosts = [
  {
    id: 7,
    title: "Adaptive Workouts: How AI Adjusts to Your Fitness Level and Goals",
    excerpt: "Fitness is a field that keeps reinventing itself, and AI-powered solutions are revolutionizing...",
    date: "August 1, 2024",
    image: adaptiveWorkoutsImage
  },
  {
    id: 1,
    title: "5 Effective HIIT Workouts You Can Do at Home",
    excerpt: "Discover high-intensity interval training workouts that don't require any equipment...",
    date: "July 29, 2024",
    image: hiitWorkoutImage
  },
  {
    id: 2,
    title: "The Ultimate Guide to Macro Tracking",
    excerpt: "Learn how to balance your macronutrients for optimal fitness and health...",
    date: "July 28, 2024",
    image: macroTrackingImage
  },
  {
    id: 3,
    title: "Strength Training Basics for Beginners",
    excerpt: "Get started with strength training and build a solid foundation for your fitness journey...",
    date: "July 27, 2024",
    image: strengthTrainingImage
  },
  {
    id: 4,
    title: "10 Nutrition Tips for Muscle Gain",
    excerpt: "Fuel your muscle growth with these essential nutrition tips and strategies...",
    date: "July 26, 2024",
    image: nutritionTipsImage
  },
  {
    id: 5,
    title: "The Best AI Tools for Fitness",
    excerpt: "In recent years, artificial intelligence has made significant strides in various industries...",
    date: "July 25, 2024",
    image: aiToolsImage
  },
  {
    id: 6,
    title: "AI-Powered Macro Tracking: Revolutionizing Your Nutrition Journey",
    excerpt: "In the ever-evolving world of fitness and nutrition, artificial intelligence has emerged as...",
    date: "July 24, 2024",
    image: macroTrackingImage2
  },
  // Add more blog posts here...
];

const ITEMS_PER_PAGE = 6;

function Blog() {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = blogPosts.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(blogPosts.length / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const PaginationButton = ({ onClick, disabled, children }) => (
    <button
      onClick={onClick}
      disabled={disabled}
      className="mx-1 px-4 py-2 border rounded-md disabled:opacity-50 touch-manipulation"
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      {children}
    </button>
  );

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold mb-8 text-center">Blog</h1>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {currentItems.map(post => (
            <Link to={`/blog/${post.id}`} key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
              <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-2">{post.title}</h2>
                <p className="text-gray-600 mb-4">{post.excerpt}</p>
                <p className="text-sm text-gray-500">{post.date}</p>
              </div>
            </Link>
          ))}
        </div>
        {totalPages > 1 && (
          <div className="flex justify-center items-center mt-8 select-none">
            <PaginationButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ChevronLeft className="h-5 w-5" />
            </PaginationButton>
            {[...Array(totalPages)].map((_, index) => (
              <PaginationButton
                key={index}
                onClick={() => handlePageChange(index + 1)}
                disabled={currentPage === index + 1}
              >
                {index + 1}
              </PaginationButton>
            ))}
            <PaginationButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <ChevronRight className="h-5 w-5" />
            </PaginationButton>
          </div>
        )}
      </div>
    </div>
  );
}

export default Blog;