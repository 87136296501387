import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react';
import iconImage from '../images/icon.png';




const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleJoinNow = () => {
        window.open('https://t.me/aifitnesstrainerbot', '_blank');
    };
    const initMovableHeader = () => {
        var lastScrollTop = 0;
        document.addEventListener("scroll", (event) => {
            const element = document.getElementsByClassName('expand-contract')[0];
            var st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop) {
                if (element.className.includes('expanded')) {
                    element.classList.toggle('expanded');
                }
            } else if (st < lastScrollTop) {
                if (!element.className.includes('expanded')) {
                    element.classList.toggle('expanded');
                }
            }
            if (window.pageYOffset > 0) {
                if (!element.className.includes('scrolled')) {
                    element.classList.toggle('scrolled');
                }
            } else {
                if (element.className.includes('scrolled')) {
                    element.classList.toggle('scrolled');
                }
            }
            lastScrollTop = st <= 0 ? 0 : st;
        });
    }

    useEffect(() => {
        initMovableHeader();
    }, [])

    return (
        <nav className="relative z-50 py-6 mb-10 hidden sm:block">
            <section className='fixed w-full expand-contract expanded'>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 flex justify-between items-center">
                    <div className="flex items-center space-x-3 ml-2 xl:-ml-8">
                        <Link to="/">
                            <img
                                src={iconImage}
                                alt="trAIner icon"
                                className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 rounded-full object-cover"
                            />
                        </Link>
                        <Link to="/" className="text-xl md:text-xl lg:text-xl xl:text-xl font-bold text-gray-800 hidden sm:block">trAIner</Link>
                    </div>
                    <div className="flex items-center space-x-4">
                        <div className="hidden sm:flex items-center space-x-6 mr-2">
                            <Link to="/#features" className="text-base md:text-lg lg:text-lg xl:text-lg text-gray-600 hover:text-gray-900">Features</Link>
                            <Link to="/blog" className="text-base md:text-lg lg:text-lg xl:text-lg text-gray-600 hover:text-gray-900">Blog</Link>
                            <a
                                href="mailto:trainerfitnesscoach@gmail.com"
                                className="text-base md:text-lg lg:text-lg xl:text-lg text-gray-600 hover:text-gray-900"
                            >
                                Contact
                            </a>
                        </div>
                        <button onClick={handleJoinNow} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 md:py-3 md:px-6 ml-4 rounded-full transition duration-300 shadow-md text-base md:text-lg lg:text-xl xl:text-xl">
                            Join Now
                        </button>
                    </div>
                </div>
            </section>
        </nav>
    );
}
export default Header;