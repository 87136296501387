import React from 'react';

const handleJoinNow = () => {
    window.open('https://t.me/aifitnesstrainerbot', '_blank');
  };

const LegalPageLayout = ({ title, children }) => (
  <div className="py-12 px-4 sm:px-6 lg:px-8">
    <div className="max-w-5xl mx-auto flex flex-col md:flex-row justify-between">
      <div className="w-full md:w-2/3 pr-8">
        <h1 className="text-4xl font-bold mb-8">{title}</h1>
        <div className="space-y-6">
          {children}
        </div>
      </div>
      <div className="w-full md:w-1/4 mt-20 md:mt-0">
        <div className="sticky top-8">
        <button 
              onClick={handleJoinNow}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-md text-base transition duration-300 shadow-md flex items-center justify-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 mr-2">
                <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.295-.6.295-.002 0-.003 0-.005 0l.213-3.054 5.56-5.022c.24-.213-.054-.334-.373-.121l-6.869 4.326-2.96-.924c-.64-.203-.654-.64.135-.954l11.566-4.458c.538-.196 1.006.128.832.941z"/>
              </svg>
              Try it on Telegram
            </button>
        </div>
      </div>
    </div>
  </div>
);

const Section = ({ title, content }) => (
  <section>
    <h2 className="text-2xl font-bold mb-2">{title}</h2>
    <p className="text-gray-700">{content}</p>
  </section>
);

export function TermsOfService() {
  return (
    <LegalPageLayout title="Terms of Service">
      <Section 
        title="1. Acceptance of Terms" 
        content="By accessing or using trAIner, you agree to be bound by these Terms of Service."
      />
      <Section 
        title="2. Description of Service" 
        content="trAIner is an AI-powered fitness coaching service delivered via Telegram. It provides personalized workout plans, nutrition advice, and motivation."
      />
      <Section 
        title="3. User Responsibilities" 
        content="You are responsible for your use of trAIner and any consequences thereof. You agree to use the service at your own risk."
      />
      <Section 
        title="4. Health Disclaimer" 
        content="trAIner is not a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition."
      />
      <Section 
        title="5. AI Limitations" 
        content="trAIner uses AI technology to provide fitness guidance. While we strive for accuracy, the AI may not always provide perfect advice. It is not a replacement for human supervision or professional coaching."
      />
      <Section 
        title="6. Liability Limitation" 
        content="We are not liable for any health issues or injuries that may result from using trAIner. You acknowledge that fitness activities carry inherent risks."
      />
      <Section 
        title="7. Data Usage" 
        content="We collect and use data as outlined in our Privacy Policy to improve our service and provide personalized recommendations."
      />
      <Section 
        title="8. Modifications to Service" 
        content="We reserve the right to modify or discontinue trAIner at any time without notice."
      />
      <Section 
        title="9. Termination" 
        content="If you wish to terminate your ongoing subscription, just type /subscriptions on the chat."
      />
    </LegalPageLayout>
  );
}

export function PrivacyPolicy() {
  return (
    <LegalPageLayout title="Privacy Policy">
      <Section 
        title="1. Information We Collect" 
        content="We collect information you provide directly to us, such as fitness goals, workout data, and nutrition information."
      />
      <Section 
        title="2. How We Use Your Information" 
        content="We use your information to provide and improve our service, including generating personalized workout and nutrition plans."
      />
      <Section 
        title="3. Data Security" 
        content="We implement reasonable security measures to protect your personal information from unauthorized access or disclosure."
      />
      <Section 
        title="4. Data Sharing" 
        content="We do not sell nor share your personal information with any third party."
      />
      <Section 
        title="5. Your Rights" 
        content="You have the right to access, correct, or delete your personal information. Contact us to exercise these rights."
      />
      <Section 
        title="6. Changes to This Policy" 
        content="We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page."
      />
      <Section 
        title="7. Contact Us" 
        content="If you have any questions about this privacy policy, please contact us at trainerfitnesscoach@gmail.com"
      />
    </LegalPageLayout>
  );
}