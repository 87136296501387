import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-white py-8 mt-16 relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center space-x-12">
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Legal</h3>
            <ul className="space-y-1">
              <li>
                <Link
                  to="/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-gray-900"
                >
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-gray-900"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          {/* <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Product</h3>
            <ul className="space-y-1">
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Features</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-900">Pricing</a></li>
            </ul>
          </div> */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Blog</h3>
            <ul className="space-y-1">
              <li><Link to="/blog" className="text-gray-600 hover:text-gray-900">Latest Posts</Link></li>
              {/* <li><a href="#" className="text-gray-600 hover:text-gray-900">Fitness Tips</a></li> */}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">Resources</h3>
            <ul className="space-y-1">
              <li>
                <a
                  href="https://www.palettehunt.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-gray-900"
                >
                  Color Palettes
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="absolute bottom-4 right-10 text-gray-500 text-sm">
        © trAIner 2024
      </div>
    </footer>
  );
}

export default Footer;