import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function Payment() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      const decoded = jwtDecode(credentialResponse.credential);
      setUser(decoded);
      await handlePayment(credentialResponse.credential);
    } catch (error) {
      console.error('Error decoding token:', error);
      setError('Failed to process Google Sign-In');
    }
  };

  const handleGoogleFailure = () => {
    console.error('Google Sign-In failed');
    setError('Google Sign-In failed. Please try again.');
  };

  const handlePayment = async (idToken) => {
    setLoading(true);
    setError(null);
    
    try {
      // This calls the checkout Lambda function
      const response = await fetch(process.env.REACT_APP_LAMBDA_URL_CHECKOUT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken }),
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const { sessionId } = await response.json();

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        throw error;
      }

      // Note: The webhook Lambda function is not called directly from here.
      // It will be triggered by Stripe after the checkout is completed.
    } catch (err) {
      console.error('Payment error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-16 px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          Unlock Your 
          <span className="inline-block bg-blue-500 text-white px-4">Full Potential</span><br />
        </h1>
        <p className="mt-3 max-w-md mx-auto text-xl text-gray-500 sm:text-2xl md:mt-5 md:max-w-3xl">
          Join trAIner Premium and transform your fitness journey today for just €9.99 per month.
        </p>
        <div className="mt-8">
          <ul className="space-y-4 text-left text-gray-600 max-w-md mx-auto">
            <li className="flex items-center">
              <svg className="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="ml-3 text-lg">AI-powered<strong> workout and macro plans</strong></span>
            </li>
            <li className="flex items-center">
              <svg className="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="ml-3 text-lg"><strong>Personalized nutrition advice</strong> & meal planning</span>
            </li>
            <li className="flex items-center">
              <svg className="flex-shrink-0 h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="ml-3 text-lg"><strong>Easy tracking</strong> of routines and macros via photos or text</span>
            </li>
          </ul>
        </div>
        <div className="mt-8 flex justify-center">
          {!user ? (
            <div className="text-center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p className="text-xl text-gray-700 mb-4">Ready to start? Sign in with Google:</p>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleFailure}
                  useOneTap
                  theme="filled_blue"
                  shape="pill"
                  size="large"
                  text="continue_with"
                  locale="en"
                />
              </GoogleOAuthProvider>
            </div>
          ) : (
            <button
              onClick={() => handlePayment(user.tokenId)}
              disabled={loading}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {loading ? 'Processing...' : 'Start Your Premium Journey'}
            </button>
          )}
        </div>
        {error && <p className="mt-4 text-red-600">{error}</p>}
      </div>
    </div>
  );
}

export default Payment;