import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import Home from './Home';
import Blog from './Blog';
import BlogPost from './BlogPost';
import Footer from './Footer';
import Payment from './Payment';
import SuccessPage from './SuccessPage';
import { TermsOfService, PrivacyPolicy } from './LegalPages';
import iconImage from './images/icon.png';
import Header from './Header/Header';
import HeaderMobile from './Header/HeaderMobile';

function ScrollToFeatures() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#features') {
      const featuresSection = document.getElementById('features');
      if (featuresSection) {
        featuresSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return null;
}

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleJoinNow = () => {
    window.open('https://t.me/aifitnesstrainerbot', '_blank');
  };

  return (
    <Router>
      <ScrollToFeatures />
      <div className="min-h-screen bg-blue-50 relative overflow-hidden">
        {/* Background shape */}
        <div
          className="absolute top-0 right-0 w-2/3 h-full bg-gradient-to-l from-blue-200 to-transparent opacity-30 rounded-l-full"
          style={{ transform: 'translateX(25%)' }}
        ></div>

        <Header />
        <HeaderMobile />

        {/* Routes */}
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/success" element={<SuccessPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;