import React, { useRef, useState } from 'react';
import trainerImage from './images/trainer.png';
import trackImage from './images/track.jpg';
import trainImage from './images/train.jpg';
import foodImage from './images/food.jpg';
import TrainerImageComponent from './TrainerImageComponent';
import previewImage from './images/preview.jpg';
import demoVideo from './images/demo.mp4';

function TrustBadge() {
  return (
    <div className="mb-10 flex items-center space-x-2">
      <div className="flex -space-x-2 overflow-hidden">
        <img className="inline-block h-8 w-8 rounded-full ring-2 ring-[#f0f4f8]" src="https://randomuser.me/api/portraits/women/17.jpg" alt="" />
        <img className="inline-block h-8 w-8 rounded-full ring-2 ring-[#f0f4f8]" src="https://randomuser.me/api/portraits/men/4.jpg" alt="" />
        <img className="inline-block h-8 w-8 rounded-full ring-2 ring-[#f0f4f8]" src="https://randomuser.me/api/portraits/women/3.jpg" alt="" />
        <img className="inline-block h-8 w-8 rounded-full ring-2 ring-[#f0f4f8]" src="https://randomuser.me/api/portraits/men/7.jpg" alt="" />
      </div>
      <span className="text-sm font-semibold text-blue-600">250+</span>
      <span className="text-sm text-gray-600">People are improving their lives</span>
    </div>
  );
}

function Home() {
  const [imageError, setImageError] = useState(false);
  const featuresRef = useRef(null);

  const handleJoinNow = () => {
    window.open('https://t.me/aifitnesstrainerbot', '_blank');
  };

  const handleImageError = () => {
    console.error("Failed to load image");
    setImageError(true);
  };

  return (
    <div className="relative z-10 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row items-center md:items-start justify-between">
          <div className="w-full md:w-3/5 lg:w-2/3 text-center md:text-left mb-8 md:mb-0 md:pr-8 lg:pr-12">
            <div className="xl:mt-8">
              <TrustBadge />
            </div>
            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-5xl xl:text-7xl font-extrabold mb-3 leading-tight text-gray-900">
              The Ultimate<br />
              <span className="inline-block bg-blue-500 text-white px-4">AI Fitness Coach</span><br />
              <span className="whitespace-nowrap">on Telegram</span>
            </h1>
            <p className="mb-6 text-gray-700 max-w-md mx-auto md:mx-0 text-lg lg:text-xl xl:text-l">
              Experience 24/7 personalized workouts, nutrition advice, and motivation with the best AI fitness coach app, right in your chat.
            </p>
            <div className="flex justify-center md:justify-start">
              <button
                onClick={handleJoinNow}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 md:py-4 md:px-6 rounded-md text-lg md:text-xl lg:text-lg xl:text-lg transition duration-300 shadow-md flex items-center justify-start"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-8 mr-1 -ml-2"
                >
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.894 8.221l-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.295-.6.295-.002 0-.003 0-.005 0l.213-3.054 5.56-5.022c.24-.213-.054-.334-.373-.121l-6.869 4.326-2.96-.924c-.64-.203-.654-.64.135-.954l11.566-4.458c.538-.196 1.006.128.832.941z" />
                </svg>
                <span>Start Your AI Fitness Journey Now</span>
              </button>
            </div>
          </div>
          <div className="w-full md:w-2/5 lg:w-1/3 flex flex-col md:block mb-10 items-center justify-center md:justify-start relative">
            <div className="w-full text-center md:hidden mt-0 mb-2">
              <div className="flex flex-col items-center justify-center space-y-4">
                <div>
                  <p className="text-3xl font-bold text-blue-600">350+</p>
                  <p className="text-xs text-gray-600">Routines Created</p>
                </div>
                <div>
                  <p className="text-3xl font-bold text-blue-600">1,200+</p>
                  <p className="text-xs text-gray-600">Fitness Questions Resolved</p>
                </div>
              </div>
            </div>
            <div className="hidden md:block w-full">
              <TrainerImageComponent trainerImage={trainerImage} handleImageError={handleImageError} />
            </div>
          </div>
        </div>
        <div className="hidden md:flex mt-16 mb-12 justify-center space-x-16">
          <div className="text-center">
            <p className="text-3xl md:text-4xl font-bold text-blue-600">350+</p>
            <p className="text-sm md:text-base text-gray-600">Routines Created</p>
          </div>
          <div className="text-center">
            <p className="text-3xl md:text-4xl font-bold text-blue-600">1,200+</p>
            <p className="text-sm md:text-base text-gray-600">Fitness Questions Resolved</p>
          </div>
        </div>

        <div className="mb-2 max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative w-full" style={{ paddingBottom: '56.25%' }}> {/* 16:9 aspect ratio */}
            <video
              className="absolute top-0 left-0 w-full h-full"
              controls
              preload="auto"
              poster={previewImage}
              title="trAIner: Your AI Fitness Coach Demo"
            >
              <source src={demoVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <TestimonialsSection />

        <div id="features" className="mb-8 text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-3">All you need, in one place</h2>
          <p className="text-gray-600 text-lg max-w-2xl mx-auto">
            <span className='font-semibold'>trAIner</span> combines cutting-edge AI technology with personalized fitness expertise,
            delivering a truly adaptive and engaging workout experience right in your Telegram chat.
          </p>
        </div>

        <div ref={featuresRef} className="grid grid-cols-1 md:grid-cols-3 gap-6 relative z-20">
          <FeatureCard
            title="Track your progress with AI-powered insights"
            description="Monitor your fitness journey with simple commands, or just a picture!"
            image={trackImage}
            imagePosition="top"
          />
          <FeatureCard
            title="Get personalized workouts tailored to your goals"
            description="Get AI-generated custom workout plans tailored to your goals, fitness level and conditions, thanks to our AI routine maker."
            image={trainImage}
            imagePosition="top"
          />
          <FeatureCard
            title="Get recipes that fit your macros with our AI-powered meal suggestions"
            description="Discover macro-friendly recipes with our AI-powered meal suggestions. Say goodbye to boring meals!"
            image={foodImage}
            imagePosition="top"
          />
        </div>
      </div>
      <FAQSection />
    </div>
  );
}


function FeatureCard({ title, description, image, imagePosition = 'center' }) {
  return (
    <div className="shadow-md shadow-blue-200 rounded-lg overflow-hidden bg-white">
      <div className="relative h-64 overflow-hidden">
        <img
          src={image}
          alt={title}
          className={`w-full object-cover absolute ${imagePosition === 'top' ? 'top-0' :
            imagePosition === 'bottom' ? 'bottom-0' :
              'top-1/2 transform -translate-y-1/2'
            }`}
        />
      </div>
      <div className="p-6 text-center">
        <h3 className="text-xl font-semibold mb-2 text-gray-800">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
}

function TestimonialsSection() {
  const testimonials = [
    {
      name: "Laura",
      image: "https://randomuser.me/api/portraits/women/32.jpg",
      quote: "<span style='font-weight: 600;'>trAIner's</span> ability to track my macros from a single food picture is amazing! I can track whatever I eat with simply a photo, anywhere I am.",
      feature: "Instant Macro Tracking"
    },
    {
      name: "Richard",
      image: "https://randomuser.me/api/portraits/men/52.jpg",
      quote: "After my hernia, <span style='font-weight: 600;'>trAIner</span> adapted my routines perfectly. The AI understands my limitations and still keeps me progressing. It also gives me the motivation I needed!",
      feature: "Adaptive AI Routines"
    },
    {
      name: "Christina",
      image: "https://randomuser.me/api/portraits/women/45.jpg",
      quote: "<span style='font-weight: 600;'>trAIner</span> suggests meals that fit my remaining macros for the day, which makes my life much easier. I don't need to squeeze my brain to find what to eat everytime.",
      feature: "Smart Meal Suggestions"
    },
    {
      name: "Manuel",
      image: "https://randomuser.me/api/portraits/men/20.jpg",
      quote: "I can get my fitness questions answered in seconds, and the AI always knows my history and preferences. I can get personalized advice anytime for a low price.",
      feature: "24/7 Fitness Support"
    }
  ];

  return (
    <div className="py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-extrabold text-gray-900 text-center mb-4">
          Users love our AI Personal Trainer
        </h2>
        <div className="mb-8 text-center">
          <p className="text-gray-600 text-lg max-w-2xl mx-auto">
            Solving your fitness questions, seamless macros tracking or creation of routines powered by AI are just of the favorite app features for our users!
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="shadow-md shadow-blue-200 rounded-lg p-6 bg-white max-w-xs mx-auto">
              <div className="flex items-center mb-4">
                <img src={testimonial.image} alt={testimonial.name} className="w-12 h-12 rounded-full mr-4" />
                <div>
                  <div className="font-semibold text-gray-900">{testimonial.name}</div>
                  <div className="text-blue-500 text-sm">{testimonial.feature}</div>
                </div>
              </div>
              <p className="text-gray-600 italic text-sm" dangerouslySetInnerHTML={{ __html: `&ldquo;${testimonial.quote}&rdquo;` }}></p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function FAQSection() {
  const faqs = [
    {
      question: "How does <span style='font-weight: 600;'>trAIner's</span> AI create personalized workouts?",
      answer: "<span style='font-weight: 600;'>trAIner</span> uses the most advanced AI model to analyze your fitness goals, current level, and preferences. We take into account past injuries, personal considerations and everything that matters to create the best possible routine for you. And you can always adjust it to your taste by interacting with our AI!"
    },
    {
      question: "Can beginners use <span style='font-weight: 600;'>trAIner</span> as their AI fitness coach?",
      answer: "Absolutely! <span style='font-weight: 600;'>trAIner</span> is designed for all fitness levels, from beginners to advanced athletes. The AI adjusts the difficulty and complexity of workouts based on your experience and progress."
    },
    {
      question: "What are the basic commands on <span style='font-weight: 600;'>trAIner</span>?",
      answer: "If you want to simply see all we offer, just type /start! We offer however many options including: /routines to create and view your workout routines, /macros to see and create your personalized macros, and /track for all your tracking needs."
    },
    {
      question: "How much does <span style='font-weight: 600;'>trAIner</span> cost?",
      answer: "<span style='font-weight: 600;'>trAIner</span> is a subscription-based service that costs €9.99 per month. This gives you access to all of our features, including routine creation, macro tracking, and more."
    }
  ];

  return (
    <div className="py-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-gray-600 text-lg max-w-2xl mx-auto">
            Get quick answers to common questions about <span className='font-semibold'>trAIner</span> and how it can help you achieve your fitness goals.
          </p>
        </div>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <FAQ key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </div>
  );
}

function FAQ({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="shadow-md shadow-blue-200 bg-white rounded-lg overflow-hidden">
      <button
        className="flex justify-between items-center w-full text-left text-lg font-medium text-gray-900 bg-transparent p-4 hover:bg-blue-50 focus:outline-none transition-colors duration-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span dangerouslySetInnerHTML={{ __html: question }} />
        <svg
          className={`w-5 h-5 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </button>
      <div
        className={`overflow-hidden transition-max-height duration-300 ease-in-out ${isOpen ? 'max-h-screen' : 'max-h-0'}`}
      >
        <div className="p-4 bg-transparent border-t-2 border-blue-200">
          <p className="text-gray-600" dangerouslySetInnerHTML={{ __html: answer }} />
        </div>
      </div>
    </div>

  );
}

export default Home;